<template>
  <div v-click-out="handleClickOutside" :data-test="testAttribute" :class="pSelectClasses">
    <p-text-input
      v-model="value"
      :label="label"
      :name="name"
      :class="props.readonly && 'cursor-pointer'"
      type="text"
      :readonly="isFocused && props.readonly"
      @click="isFocused = true"
    >
      <template #append>
        <p-icon
          size="sm"
          icon="chevron-down"
          color="fg-secondary"
          :class="['p-select__icon mr-2', { 'p-select__icon--reverse': isFocused }]"
        />
      </template>
    </p-text-input>

    <transition name="slide-fade-down" mode="in-out">
      <component
        :is="dropdownComponent"
        v-if="isFocused && !props.disabled"
        class="p-select__dropdown"
        :title="label"
        show-close
        @on-close="isFocused = false"
      >
        <div class="p-select__dropdown-container">
          <div v-if="items.length > 10" class="p-12">
            <p-search-input v-model="search" size="md" :placeholder="searchPlaceholder" />
          </div>
          <div class="p-select__content">
            <div v-for="item in filteredItems" :key="item.value" class="p-select__item">
              <label class="place-items-between py-8 px-12">
                <input
                  :id="item.value.toString()"
                  v-model="selectedOption"
                  type="radio"
                  :value="item.value"
                  :name="name"
                  @click.stop="handleItemClick(item)"
                />
                <span class="f-utility-14-medium t-fg-primary" v-text="item.label" />
                <p-icon v-if="item.value === selectedOption" size="sm" icon="check" />
              </label>
              <p-divider class="mx-12 my-2" />
            </div>
          </div>
        </div>
      </component>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
const { $device } = useNuxtApp()

interface PSelect {
  value: string | number
  label: string
}

const emit = defineEmits(['on-change'])

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  items: {
    type: Array as PropType<Array<PSelect>>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    default: '',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  selectedOption: {
    type: String,
    default: '',
  },
})

const testAttribute = dataTest(props.name)
const isFocused = ref(false)
const search = ref('')
const selectedOption = ref(props.selectedOption)
const name = toRef(props, 'name')

const modelValue = defineModel<string>('modelValue')

const { value } = useField(name, undefined, {
  initialValue: modelValue.value,
})

const filteredItems = computed(() => {
  if (search.value) {
    return props.items.filter((item) => {
      const searchString = turkishToEnglish(item?.label + item?.value).toLowerCase?.()
      return searchString.includes(turkishToEnglish(search?.value?.toLowerCase?.()))
    })
  }
  return props.items
})

const pSelectClasses = computed<Record<string, boolean>>(() => {
  return {
    'p-select': true,
    'p-select--focused': isFocused.value,
    'p-select--disabled': props.disabled,
  }
})

const dropdownComponent = computed(() => {
  return $device.isDesktop ? 'div' : resolveComponent('p-bottom-sheet')
})

watch(
  () => value.value,
  () => (modelValue.value = value.value),
)

watch(
  () => modelValue.value,
  () => (value.value = modelValue.value || ''),
)

function handleItemClick(item: PSelect): void {
  value.value = item.label
  isFocused.value = false
  emit('on-change', item)
}

function handleClickOutside(): void {
  if ($device.isDesktop) isFocused.value = false
}
</script>
